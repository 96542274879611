import Bus from './bus'
import axios from "axios";
import { Group } from 'three';
class chat {
    constructor() {
        this.ws = {},
            this.msgList = [
                []
            ],
            this.role = {

            },

            this.timmer = ""
        this.playerList = []
    }
    login(role) {
        var that = this
        this.role = role
        function wsConnect(role) {
            axios
                .get('https://api.wh12345.net:9400/assign/', {
                    params: {
                        name: role.name,
                        roomid: role.roomid
                    },
                })
                .then((res) => {
                    if (res.data.code == 0) {
                        that.ws = new WebSocket(`wss://api.wh12345.net:${res.data.port}/`);
                        that.port = res.data.port
                        that.ws.onopen = function () {
                            // that.ws.send(`login,${role.name},${role.sex},${role.accInfo},${role.bodyColor},${role.hairColor},${role.auth}`);
                            that.ws.send(`login,${role.name},${role.roomid},${role.rid}`)
                            // Bus.$emit('loginS', '')
                            that.ws.onmessage = function (evt) {
                                var msg = evt.data.split(',')
                                var cmd = evt.data.split(':')


                                Bus.$emit('playermsg', evt)
                                if (cmd[0] == '{"code"') {
                                    Bus.$emit('player', JSON.parse(evt.data))
                                    this.list = JSON.parse(evt.data)
                                }
                                if (msg[0] == 'logsuccess') {
                                    that.role.id = msg[1]
                                }


                                if (msg[0] == 'chats') {
                                    that.msgList[0].push({
                                        name: msg[1],
                                        msg: msg[2],
                                    })
                                    Bus.$emit('msgList', that.msgList)
                                }


                                if (msg[0] == 'chat') {

                                    that.msgList.push([])
                                    var sendid = msg[1]
                                    var reid = msg[2]
                                    var value = msg[4] * 1

                                    if (that.role.name == sendid) {
                                        that.msgList[value].push({
                                            name: msg[1],
                                            msg: msg[3]
                                        })
                                        Bus.$emit('msgList', that.msgList)
                                    } else if (that.role.name == reid) {
                                        Bus.$emit('reciveMsg', {
                                            name: msg[1],
                                            msg: msg[3],
                                            from: sendid
                                        })
                                    }
                                }

                                if (msg[1] == 'manage') {
                                    if (that.role.name == msg[2]) {
                                        console.log(that.role.name == msg[2])
                                        Bus.$emit('manage', msg[3])
                                    }
                                }

                                if (msg[1] == "zhuchiren") {
                                    if (that.role.name == msg[2]) {
                                        Bus.$emit('zhuchirenhuifu', msg[3])
                                    }
                                }



                            }
                        };
                    } else {
                        Bus.$emit('loginError4', '')
                    }
                })

        }

        wsConnect(role)
        setInterval(() => {
            // axios
            //     .get('https://api.wh12345.net:9400/players/')
            //     .then((res) => {
            //         that.playerList = res.data;
            //         Bus.$emit('playerList', that.playerList)
            //     })

            this.ws.send('players')
            // this.ws.send('close')
            setTimeout(() => {
                if (this.ws.readyState == 1) {
                    // 已经链接并且可以通讯
                    this.ws.send('ping')
                } else if (this.ws.readyState == 2) {
                    this.ws.onclose = function (e) {
                        wsConnect(role)
                    };
                } else if (this.ws.readyState == 3) {
                    // 连接已关闭或者没有链接成功
                    wsConnect(role)
                }
            }, 500)


        }, 3000)

    };
    send(e) {
        this.ws.send(e)
    }
}
export { chat }
