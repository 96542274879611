import * as THREE from 'three/build/three.module';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
// import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
// import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
// import { Line2 } from 'three/examples/jsm/lines/Line2';
// import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
// import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2.js';
// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
// import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
// import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
var idx=0;

function compareFunction(propertyName){
    return function (src,tar) {
        //获取比较的值
        var v1=src[propertyName];
        var v2=tar[propertyName];
        if(v1>v2){
            return 1;
        }
        if(v1<v2){
            return -1;
        }
        return 0;
    };
}
class ani_object
{
    constructor()
    {
        this.object=null;
        this.ani_type=3;//按位判断 //0=SRT；1=visible；2=color;3=transparent；4=uv;5=自发光;6=lightmap;7=skeleton_action；9=camera
        this.keys=[];
    }
    clone=()=>
    {
        let jsonobj = this.to_json_object();
        let newobj = new ani_object();
        newobj.from_json(jsonobj);
        newobj.object=this.object;
        newobj.ani_type=this.ani_type;
        return newobj;
    }
    
    move_key=(key,newtime)=>
    {
        key.time=newtime;
        this.keys.sort(compareFunction("time"));
    }

    from_json=(objj)=>
    {
        let aniobj=this;
        aniobj.ani_type=objj.ani_type;
        if (aniobj.ani_type==0)//旧格式支持
        {
            aniobj.ani_type=3;
        }
        aniobj.objid=objj.objid;
        aniobj.keys=objj.keys;
        for (let j=0;j<aniobj.keys.length;j++)
        {
            let key=aniobj.keys[j];
            if (aniobj.ani_type&1)
            {
                key.value.position=new THREE.Vector3(key.value.position.x,key.value.position.y,key.value.position.z);
                let quaternion=new THREE.Quaternion();
                quaternion.set(key.value.quaternion._x,key.value.quaternion._y,key.value.quaternion._z,key.value.quaternion._w).normalize();;
                key.value.quaternion=quaternion.normalize();
                key.value.scale=new THREE.Vector3(key.value.scale.x,key.value.scale.y,key.value.scale.z);
            }
            if (aniobj.ani_type&(1<<2))
            {
                key.value.color = new THREE.Color(key.value.color);
            }
            if (aniobj.ani_type&(1<<3))
            {
                if (key.value.transparent==null)
                {
                    key.value.transparent=true;
                }
            }
            if (aniobj.ani_type&(1<<4))
            {
                key.value.uv_offset = new THREE.Vector2(key.value.uv_offset.x,key.value.uv_offset.y);
                key.value.uv_repeat = new THREE.Vector2(key.value.uv_repeat.x,key.value.uv_repeat.y);
            }
            if (aniobj.ani_type&(1<<5))
            {
                key.value.ill_color = new THREE.Color(key.value.ill_color);
            }
            if (aniobj.ani_type&(1<<9))
            {
                key.value.eye = new THREE.Vector3(key.value.eye.x,key.value.eye.y,key.value.eye.z);
                key.value.target = new THREE.Vector3(key.value.target.x,key.value.target.y,key.value.target.z);
            }
        }
    }
    record=(time)=>
    {
        let key = {};
        key.time = time;
        key.value = {};
        key.ease=0;
        if (this.ani_type&1)//位置
        {
            let position = this.object.position.clone();
            let quaternion = this.object.quaternion.clone();
            let scale = this.object.scale.clone();
            key.value.position = position;
            key.value.quaternion = quaternion;
            key.value.scale = scale;
        }
        
        if (this.ani_type&(1<<1))//visible
        {
            key.value.visible = this.object.visible;
        }

        if (this.ani_type&(1<<2))//color
        {
            if (this.object.color)
            {
                key.value.color = this.object.color.clone();
            }
            if (this.object.material&&this.object.material.color)
            {
                key.value.color = this.object.material.color.clone();
            }
        }
        if (this.ani_type&(1<<3))//alpha
        {
            if (this.object.material)
            {
                key.value.opacity = this.object.material.opacity;
                key.value.transparent = this.object.material.transparent
            }
        }

        if (this.ani_type&(1<<4))//alpha
        {
            if (this.object.material&&this.object.material.map)
            {
                key.value.uv_offset = this.object.material.map.offset.clone();
                key.value.uv_repeat = this.object.material.map.repeat.clone();
                key.value.uv_rotation = this.object.material.map.rotation;
            }else{
                key.value.uv_offset=new THREE.Vector2(0,0);
                key.value.uv_repeat=new THREE.Vector2(1,1);
                key.value.uv_rotation=0;
            }
        }
        if (this.ani_type&(1<<5))//emissive
        {
            if (this.object.material&&this.object.material.emissive)
            {
                key.value.ill_color = this.object.material.emissive.clone();
                key.value.ill_power = this.object.material.emissiveIntensity;
            }
        }
        if (this.ani_type&(1<<6))//lightmap
        {
            if (this.object.material&&this.object.material.emissive)
            {
                key.value.lightMapIntensity = this.object.material.lightMapIntensity;
                key.value.aoMapIntensity = this.object.material.aoMapIntensity;
            }
        }
        if (this.ani_type&(1<<7))//lightmap
        {
            if (this.object&&this.object.bSkeleton)
            {
                key.value.clip_name = this.object.clip_name;
            }
        }

        if (this.ani_type&(1<<9))//lightmap
        {
            if (this.object)
            {
                key.value.eye = this.object.camera.position.clone();
                key.value.target = this.object.cameraControls.target.clone();
            }
        }


        ////
        let bfound=false;
        for (let ii=0;ii<this.keys.length;ii++)
        {
            let key1=this.keys[ii];
            if (key1.time==time)
            {
                this.keys[ii]=key;
                bfound=true;
            }
        }
        if (!bfound)
        {
            this.keys.push(key);
            this.keys.sort(compareFunction("time"));
        }
    }
    update=(time)=>
    {
        if (!this.object) return;
        if (this.keys.length==0)
        {
            return;
        }
        let key1=null;
        let key2=null;
        let alpha=0;
        if (this.keys.length==1)
        {
            key1=this.keys[0];
            key2=this.keys[0];
        }else{
            let bFound=false;
            for (let i=0;i<this.keys.length-1;i++)
            {
                key1=this.keys[i];
                key2=this.keys[i+1];
                if (key1.time>time)
                {
                    bFound=true;
                    key1=this.keys[0];
                    key2=this.keys[0];
                    break;
                }
                if (key1.time<=time&&key2.time>time)
                {
                    bFound=true;
                    alpha = (time-key1.time)/(key2.time-key1.time);
                    break;
                }
            }
            if (!bFound)
            {
                alpha=0;
                key1=this.keys[this.keys.length-1];
                key2=key1;
            }
        }
        ////////////////////////////////////////////////////
        //插值运算
        let ease = key1.ease;
        if (ease!=0)
        {
            let p = Math.exp(Math.abs(ease/100.0));
            if (ease<0)
            {
                alpha=1-Math.pow((1-alpha),p);
            } else
            {
                alpha=Math.pow(alpha,p);
            }
        }
        if (this.ani_type&1)//位置控制
        {
            let position = key1.value.position.clone();
            position.lerp(key2.value.position,alpha);
                      
            var startQuaternion = key1.value.quaternion.clone();
            startQuaternion.slerp(key2.value.quaternion,alpha);
            let scale = key1.value.scale.clone();
            scale.lerp(key2.value.scale,alpha);

            this.object.position.set(position.x,position.y,position.z);
            this.object.quaternion.set(startQuaternion.x,startQuaternion.y,startQuaternion.z,startQuaternion.w);
            this.object.scale.set(scale.x,scale.y,scale.z);
        }
        if (this.ani_type&(1<<1))//visible
        {
           this.object.visible = key1.value.visible;
        }
        
        if (this.ani_type&(1<<2))//color
        {
            if (this.object.color)
            {
                let color1 = key1.value.color.clone();
                let color2 = key2.value.color.clone();
                color1.lerp(color2,alpha);
                this.object.color.setRGB(color1.r,color1.g,color1.b);
            }
            if (this.object.material&&this.object.material.color)
            {
                let color1 = key1.value.color.clone();
                let color2 = key2.value.color.clone();
                color1.lerp(color2,alpha);
                this.object.material.color.setRGB(color1.r,color1.g,color1.b);
            }
        }
        if (this.ani_type&(1<<3))//alpha
        {
            if (this.object.material)
            {
                this.object.material.opacity=(1-alpha)*key1.value.opacity+alpha*key2.value.opacity;
                this.object.material.transparent=key1.value.transparent;
            }
        }
        if (this.ani_type&(1<<4))//uv
        {
            if (this.object.material&&this.object.material.map)
            {
                let offset = key1.value.uv_offset.clone();
                offset.lerp(key2.value.uv_offset,alpha);
                let repeat = key1.value.uv_repeat.clone();
                repeat.lerp(key2.value.uv_repeat,alpha);

                this.object.material.map.offset.set(offset.x,offset.y);
                this.object.material.map.repeat.set(repeat.x,repeat.y);
                this.object.material.map.rotation = (1-alpha)*key1.value.uv_rotation+alpha*key2.value.uv_rotation;
            }
        }
        if (this.ani_type&(1<<5))//emissive
        {
            if (this.object.material&&this.object.material.emissive)
            {
                let color1 = key1.value.ill_color.clone();
                color1.lerp( key2.value.ill_color,alpha);
                this.object.material.emissive.setRGB(color1.r,color1.g,color1.b);
                this.object.material.emissiveIntensity = (1-alpha)*key1.value.ill_power+alpha*key2.value.ill_power;
            }
        }
        if (this.ani_type&(1<<6))//lightmap
        {
            if (this.object.material&&this.object.material.aoMapIntensity!=null)
            {
                this.object.material.aoMapIntensity = (1-alpha)*key1.value.aoMapIntensity+alpha*key2.value.aoMapIntensity;
                this.object.material.lightMapIntensity = (1-alpha)*key1.value.lightMapIntensity+alpha*key2.value.lightMapIntensity;
            }
        }
        if (this.ani_type&(1<<7))//lightmap
        {
            if (this.object&&this.object.bSkeleton)
            {
                if (this.object.clip_name!=key1.value.clip_name)
                {
                    document.o2.play_skeleton_clip(this.object,key1.value.clip_name,1);
                }
            }
        }
        if (this.ani_type&(1<<9))//camera
        {
            if (this.object.camera&&this.object.cameraControls)
            {
                let eye = key1.value.eye.clone();
                let target = key1.value.target.clone();
                eye.lerp(key2.value.eye,alpha);
                target.lerp(key2.value.target,alpha);
                this.object.camera.position.set(eye.x,eye.y,eye.z);
                this.object.cameraControls.target.set(target.x,target.y,target.z);
            }
        }
    }
    to_json_object=()=>
    {
        let obj={};
        if (this.object)
        {
            obj.objid=this.object.uuid;
            obj.objname=this.object.name;
        }
        obj.ani_type=this.ani_type;
        obj.keys=this.keys;
        return obj;
    }
}
class timeline
{
    constructor()
    {
        this.name="timeline";
        this.current_time = 0;
        this.total_time=10;
        this.bPlaying=false;
        this.LoopType=0;
        this.objects=[];
        this.scripts=[];
        this.uuid="";
        this.type="timeline";
        this.autoPlay=false;
        this.rate=1;
    }
    excute_script=(key)=>{
        if (this.o2&&this.o2.script&&this.o2.script.timeline)
        {
            this.o2.script.timeline(this,key);
        }
    }
    frame_move=(time)=>{
        time=time*this.rate;
        if (!this.bPlaying)
        {
            return;
        }
        let oldtime = this.current_time;
        if (this.LoopType==0)//正向播放一次
        {
            this.current_time+=time;
            if (this.current_time>this.total_time)
            {
                this.current_time=this.total_time;
                this.bPlaying=false;
            }
        }
        if (this.LoopType==1)//正向循环播放
        {
            this.current_time+=time;
            if (this.current_time>this.total_time)
            {
                this.current_time=0;
            }
        }
        if (this.LoopType==2)//逆向播放一次
        {
            this.current_time-=time;
            if (this.current_time<0)
            {
                this.current_time=0;
                this.bPlaying=false;
            }
        }
        if (this.LoopType==3)//逆向循环播放
        {
            this.current_time-=time;
            if (this.current_time<0)
            {
                this.current_time=this.total_time;
            }
        }
        let old_loop = this.LoopType;
        if (old_loop==4)//钟摆播放的正向
        {
            this.current_time+=time;
            if (this.current_time>this.total_time)
            {
                this.current_time=this.total_time;
                this.LoopType=5;
            }
        }
        if (old_loop==5)//钟摆播放的逆向
        {
            this.current_time-=time;
            if (this.current_time<0)
            {
                this.current_time=0;
                this.LoopType=4;
            }
        }
        //更新对象
        if(oldtime!=this.current_time)
        {
            //脚本
            {
                for (let i=0;i<this.scripts.length;i++)
                {
                    let key = this.scripts[i];
                    if (this.LoopType==0)//正向播放一次
                    {
                        if (key.time>oldtime&&key.time<=this.current_time)
                        {
                            this.excute_script(key);
                        }
                    }
                    if (this.LoopType==1)//正向循环播放
                    {
                        if (this.current_time==0)//循环到第一帧了
                        {
                            if (key.time>oldtime&&key.time<=this.total_time)
                            {
                                this.excute_script(key);
                            }
                        }else{
                            if (key.time>oldtime&&key.time<=this.current_time)
                            {
                                this.excute_script(key);
                            }
                        }
                    }
                    if (this.LoopType==2)//逆向播放一次
                    {
                        if (key.time<oldtime&&key.time>=this.current_time)
                        {
                            this.excute_script(key);
                        }
                    }
                    if (this.LoopType==3)//逆向循环播放
                    {
                        if (this.current_time==this.total_time)//循环到第一帧了
                        {
                            if (key.time<oldtime)
                            {
                                this.excute_script(key);
                            }
                        }else{
                            if (key.time<oldtime&&key.time>=this.current_time)
                            {
                                this.excute_script(key);
                            }
                        }
                    }
                    if (old_loop==4)//钟摆播放的正向
                    {
                        if (key.time>oldtime&&key.time<=this.current_time)
                        {
                            this.excute_script(key);
                        }
                    }
                    if (old_loop==5)//钟摆播放的逆向
                    {
                        if (key.time<oldtime&&key.time>=this.current_time)
                        {
                            this.excute_script(key);
                        }
                    }
                }

                ///跳转后的脚本
                for (let i=0;i<this.scripts.length;i++)
                {
                    let key = this.scripts[i];
                    if (this.LoopType==1)//正向循环播放
                    {
                        if (this.current_time==0)//循环到第一帧了
                        {
                            if (key.time==0)
                            {
                                this.excute_script(key);
                            }
                        }
                    }
                    if (this.LoopType==3)//逆向循环播放
                    {
                        if (this.current_time==this.total_time)//循环到第一帧了
                        {
                            if (key.time==this.total_time)
                            {
                                this.excute_script(key);
                            }
                        }
                    }
                }
            }
            this.update(this.current_time);
        }
    }
    
    goto=(time)=>
    {
        this.current_time=time;
        this.update(this.current_time);
    }
    BindItem=(obj,type)=>
    {   
        //判断重复的对象
        for (let i=0;i<this.objects.length;i++)
        {
            let aniobj=this.objects[i];
            if (aniobj.ani_type==type&&aniobj.object==obj)
            {
                return null;
            }
        }
        let ao = new ani_object;
        ao.object=obj;
        ao.ani_type=type;
        this.objects.push(ao);
        return ao;
    }
    BindCamera=()=>
    {
        let ao = new ani_object;
        ao.object=new THREE.Object3D;
        ao.object.name="_TLCameraCTRL";
        ao.object.camera=this.o2.camera;
        ao.object.cameraControls=this.o2.cameraControls;
        ao.ani_type=1<<9;
        this.objects.push(ao);
        return ao;
    }
    RecordCamera=(time)=>
    {
        for (let i=0;i<this.objects.length;i++)
        {
            let aniobj=this.objects[i];
            if (aniobj.object.name=="_TLCameraCTRL")
            {
                aniobj.record(time);
            }
        }
    }
    DelItem=(obj)=>
    {   
        //判断重复的对象
        for (let i=0;i<this.objects.length;i++)
        {
            let aniobj=this.objects[i];
            if (aniobj.object==obj)
            {
                return this.objects.splice(i,1);
            }
        }
        return null;
    }
    remove_aniobj=(aniobj)=>
    {   
        //判断重复的对象
        for (let i=0;i<this.objects.length;i++)
        {
            let aniobj2=this.objects[i];
            if (aniobj2==aniobj)
            {
                return this.objects.splice(i,1);
            }
        }
        return null;
    }
    to_json_object=()=>
    {
        let obj={};
        obj.total_time=this.total_time;
        obj.LoopType=this.LoopType;
        obj.autoPlay=this.autoPlay;
        obj.uuid=this.uuid;
        obj.type=this.type;
        obj.name=this.name;
        if (this.export)  obj.export=1;
        obj.current_time=this.current_time;
        obj.objects=[];
        obj.scripts=this.scripts;
        for (let i=0;i<this.objects.length;i++)
        {
            obj.objects.push(this.objects[i].to_json_object());
        }
        return obj;
    }
    update=(time)=>
    {
        if (time<0)
        {
            time=0;
        }
        if (time>this.total_time) time>this.total_time;
        this.current_time=time;
        for (let i=0;i<this.objects.length;i++)
        {
            let aniobj=this.objects[i];
            aniobj.update(time*100);
            //材质同步
            //按位判断 //0=SRT；1=visible；2=color;3=transparent；4=uv;5=自发光;6=lightmap;7=skeleton_action；9=camera
            if (this.o2&&this.o2.syn_material)
            {
                if (aniobj.ani_type&(1<<2|1<<3|1<<5|1<<6)) this.o2.syn_material(aniobj.object);
            }
        }
    }
    record=(time,obj)=>
    {
        for (let i=0;i<this.objects.length;i++)
        {
            let aniobj=this.objects[i];
            if (aniobj.object==obj)
            {
                aniobj.record(time);
            }
        }
        this.current_time=time/100;
    }
    play=()=>
    {
        this.bPlaying=true;
    }
    stop=()=>
    {
        this.bPlaying=false;
    }
    remove_key=(obj)=>
    {
        let aniobj = this.objects[obj.objidx];
        if (aniobj)
        {
            let newkeys=[];
            for (let i=0;i<aniobj.keys.length;i++)
            {
                let key = aniobj.keys[i];
                if (key.time!=obj.old_time)
                {
                    newkeys.push(key);
                }
            }
            aniobj.keys=newkeys;
        }
    }
    move_key=(obj)=>
    {
        let aniobj = this.objects[obj.objidx];
        if (aniobj)
        {
            for (let i=0;i<aniobj.keys.length;i++)
            {
                let key = aniobj.keys[i];
                if (key.time==obj.old_time)
                {
                    key.time=obj.time;
                    aniobj.keys.sort(compareFunction("time"));
                    this.update(key.time/100);
                    break;
                }
            }
        }
    }
    update_key_ease=(obj)=>
    {
        let aniobj = this.objects[obj.objidx];
        if (aniobj)
        {
            for (let i=0;i<aniobj.keys.length;i++)
            {
                let key = aniobj.keys[i];
                if (key.time==obj.time)
                {
                    key.ease=obj.ease;
                    this.update(key.time/100);
                    break;
                }
            }
        }
    }
    update_key_ease2=(obj,time,ease)=>
    {
        for (let j=0;j<this.objects.length;j++)
        {
            let aniobj=this.objects[j];
            if (aniobj.object!=obj)
            {
                continue;
            }
            for (let i=0;i<aniobj.keys.length;i++)
            {
                let key = aniobj.keys[i];
                if (key.time==time)
                {
                    key.ease=ease;
                    this.update(key.time/100);
                    break;
                }
            }
        }
    }
}
class o2animation
{
    constructor()
    {
        this.timelines=[];
    }
    to_json_object=()=>
    {
        let animation = {};
        animation.timelines=[];
        for (let i=0;i<this.timelines.length;i++)
        {
            animation.timelines.push(this.timelines[i].to_json_object());
        }
        return animation;
    }
    to_json_object_export=()=>
    {
        let animation = {};
        animation.timelines=[];
        for (let i=0;i<this.timelines.length;i++)
        {
            if (this.timelines[i].export) animation.timelines.push(this.timelines[i].to_json_object());
        }
        return animation;
    }
    NewTimeLine=(obj)=>
    {   
        let tl = new timeline;
        tl.o2=this.o2;
        tl.name=obj.name;
        if (!obj.uuid)
        {
            let obj2=new THREE.Object3D();
            obj.uuid=obj2.uuid;
        }
        tl.uuid=obj.uuid;
        this.timelines.push(tl);
        console.log(this.timelines);
        return tl;
    }
    get_timeline_by_name=(name)=>
    {   
        for (let i=0;i<this.timelines.length;i++)
        {
            if(this.timelines[i].name==name)
            {
                return this.timelines[i];
            }
        }
        return null;
    }
    get_timeline_by_id=(uuid)=>
    {   
        for (let i=0;i<this.timelines.length;i++)
        {
            if(this.timelines[i].uuid==uuid)
            {
                return this.timelines[i];
            }
        }
        return null;
    }
    from_json=(obj)=>
    {   
        this.timelines=[];
        if (!obj) return;
        for (let i=0;i<obj.timelines.length;i++)
        {
            let tl = this.from_tl_json(obj.timelines[i]);
            if (tl)
            {
                this.timelines.push(tl);
            }
        }
    }
    from_json_export=(obj)=>
    {   
        let timelines=[];
        if (!obj) return timelines;
        for (let i=0;i<obj.timelines.length;i++)
        {
            let tl = this.from_tl_json(obj.timelines[i]);
            if (tl)
            {
                timelines.push(tl);
            }
        }
        return timelines;
    }
    from_tl_json=(obj)=>
    {
        let tl = new timeline;
        tl.o2=this.o2;
        tl.name=obj.name;
        tl.total_time=obj.total_time;
        tl.LoopType=obj.LoopType;
        tl.current_time=obj.current_time;
        tl.autoPlay=obj.autoPlay;
        tl.uuid = obj.uuid;
        if (obj.scripts) tl.scripts=obj.scripts;
        for (let i=0;i<obj.objects.length;i++)
        {
            let objj = obj.objects[i];
            let aniobj = new ani_object;
            aniobj.from_json(objj);
            tl.objects.push(aniobj);
        }
        if (tl.autoPlay)
        {
            tl.play();
        }
        return tl;
    }
    frame_move=(time)=>
    {
        for (let i=0;i<this.timelines.length;i++)
        {
            let tl = this.timelines[i];
            tl.frame_move(time);
        }
    }
    bind_objects=()=>
    {
        for (let i=0;i<this.timelines.length;i++)
        {
            let tl = this.timelines[i];
            for (let j=0;j<tl.objects.length;j++)
            {
                let aniobj = tl.objects[j];
                if (aniobj.ani_type&(1<<9))
                {
                    aniobj.object=new THREE.Object3D();
                    aniobj.object.name="_TLCameraCTRL"
                    aniobj.object.camera=this.o2.camera;
                    aniobj.object.cameraControls=this.o2.cameraControls;
                    continue;
                }
                aniobj.object=this.o2.search_obj_by_guid(this.o2.scene,aniobj.objid);
            }
        }
    }
    search_object_by_guid=(object,uuid)=>
    {
        if (object.uuid==uuid) return object;
        for (let i=0;i<object.children.length;i++)
        {
            let c = object.children[i];
            let obj2 = this.search_object_by_guid(c,uuid);
            if (obj2) return obj2;
        }
        return null;
    }
    bind_objects_export=(timelines,object)=>
    {
        for (let i=0;i<timelines.length;i++)
        {
            let tl = timelines[i];
            for (let j=0;j<tl.objects.length;j++)
            {
                let aniobj = tl.objects[j];
                aniobj.object=this.search_object_by_guid(object,aniobj.objid);
            }
        }
    }
    remove_timeline=(tl2)=>
    {
        let new_tls = [];
        for (let i=0;i<this.timelines.length;i++)
        {
            let tl = this.timelines[i];
            if (tl!=tl2)
            {
                new_tls.push(tl);
            }
        }
        this.timelines=new_tls;
    }
    
}

export {o2animation};